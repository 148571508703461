import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppCard, WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { RowClickedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'react-use'

import { usePatchTaskApi } from 'api/canvas/mutation/usePatchTaskApi'
import { useFetchTemplateTaskListApi } from 'api/templates/queries/useFetchTemplateTaskListApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, Table } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useStableCallback } from 'hooks/useStableCallback'
import { useTemplate } from 'hooks/useTemplate'
import { SelectOsGroup } from 'pages/project/components/canvas/components/selectOsGroup/SelectOsGroup'
import { useOpenTemplateTaskPreviewModal } from 'pages/project/hooks/useOpenTemplateTaskPreviewModal'
import styles from 'pages/template/tasks/TemplateTasks.module.scss'
import { TemplateTasksFilters } from 'pages/template/tasks/TemplateTasksFilters'
import { queryClient } from 'providers/osQueryClient/utils'
import { LocalStorageKey } from 'types/common/localStorage'
import { DetailsModalType } from 'types/common/utils'
import { TemplateTask, TemplateTasksFilter } from 'types/templates/tasks'
import { hasClosestInteractiveElement } from 'utils/dom'

export const initialTasksFilters: TemplateTasksFilter = {
  search: '',
  group: undefined,
}

export const TemplateTasks = () => {
  const { t } = useTranslation()
  const { template } = useTemplate()
  const gridRef = useRef<AgGridReact<TemplateTask>>(null)

  useOpenTemplateTaskPreviewModal()

  const [, setSearchParams] = useSearchParams()

  const {
    osContext: { userDetails },
  } = useOs()

  const [lsFilters, setLsFilters] = useLocalStorage<TemplateTasksFilter>(
    `${LocalStorageKey.PROJECT_TIMELINE_FILTERS}:${template.id}:${userDetails.id}`,
    {} as TemplateTasksFilter,
  )

  const [filters, setFilters] = useState<TemplateTasksFilter>({
    ...initialTasksFilters,
    ...lsFilters,
  })

  const { data: tasks, isLoading } = useFetchTemplateTaskListApi({
    params: {
      id: template.id,
      text: filters.search,
      ...(filters.group && { groupId: filters.group }),
    },
    keepPreviousData: true,
  })

  const { mutateAsync: handleUpdateTask } = usePatchTaskApi()

  useEffect(() => {
    const { search, ...rest } = filters
    setLsFilters(rest)
  }, [setLsFilters, filters])

  const openTaskModal = useCallback(
    (data: TemplateTask) => setSearchParams({ view: DetailsModalType.TEMPLATE_TASK_DETAILS_PREVIEW, id: data.id }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const setSearchDebounced = useDebounceFn((e: WppInputCustomEvent<InputChangeEventDetail>) => {
    const search = e.detail.value || ''
    const searchQuery = search.trim().length >= 2 ? search.trim() : undefined
    setFilters(filters => ({ ...filters, search: searchQuery }))
  }, 300)

  const handleGroupChange = useCallback(
    async (data: { id: string; groupId: string }) => {
      await handleUpdateTask({
        id: data.id,
        groupId: data.groupId || null,
      })
      queryClient.invalidateQueries([ApiQueryKeys.TEMPLATE_TASKS_FETCHER])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID])
    },
    [handleUpdateTask],
  )

  const filtersApplied = !!filters.search || !!filters.group

  const noRowsOverlayComponent = useCallback(() => {
    return (
      <EmptyState
        title={
          !tasks.length && !filtersApplied
            ? t('tasksDashboard.table.empty_state_no_tasks')
            : t('common.no_search_results')
        }
        testToken="tasks"
        filtersApplied={filtersApplied}
        description={
          !tasks.length && !filtersApplied
            ? t('tasksDashboard.table.empty_state_no_tasks_description')
            : t('common.no_results_description')
        }
      />
    )
  }, [tasks.length, filtersApplied, t])

  const columnDefs = useMemo<ColDef<TemplateTask>[]>(() => {
    return [
      {
        colId: 'task_name',
        flex: 3,
        minWidth: 250,
        headerName: t('template.tasks.table.task_name')!,
        cellRenderer: ({ data }) => (
          <WppTypography className={styles.overflow} type="s-body" title={data!.name} data-testid="task_name">
            {data!.name}
          </WppTypography>
        ),
      },
      {
        colId: 'group',
        flex: 1,
        minWidth: 175,
        headerName: t('template.tasks.table.group')!,
        cellStyle: { overflow: 'visible' },
        cellRenderer: ({ data }) => (
          <div className={styles.groupContainer}>
            <SelectOsGroup
              initialValue={data?.group}
              value={data?.group?.id}
              onChange={async group => {
                if (data) {
                  handleGroupChange({ groupId: group, id: data.id })
                }
              }}
              data-testid="task_group"
            />
          </div>
        ),
      },
    ]
  }, [handleGroupChange, t])

  const handleOnRowClicked = useStableCallback(({ event, data }: RowClickedEvent<TemplateTask>) => {
    const target = event?.target as HTMLElement

    if (data && !hasClosestInteractiveElement(target)) openTaskModal(data)
  })

  return (
    <Flex direction="column">
      <div className={clsx(styles.viewContainer)}>
        <WppCard className={clsx(styles.card)}>
          <TemplateTasksFilters filters={filters} setFilters={setFilters} setSearchDebounced={setSearchDebounced} />

          {isLoading ? (
            <Flex justify="center">
              <WppSpinner size="m" />
            </Flex>
          ) : (
            <Table
              className={styles.table}
              ref={gridRef}
              rowModelType="clientSide"
              domLayout="autoHeight"
              rowData={tasks}
              cacheBlockSize={50}
              rowHeight={48}
              columnDefs={columnDefs}
              rowSelection="multiple"
              suppressPaginationPanel
              suppressRowClickSelection
              onRowClicked={handleOnRowClicked}
              noRowsOverlayComponent={noRowsOverlayComponent}
            />
          )}
        </WppCard>
      </div>
    </Flex>
  )
}
