import { WppIconOrganization, WppTypography, WppBreadcrumb } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { Location } from 'types/projects/tasks'

interface Props {
  location: Location
}

export const TaskLocation = ({ location }: Props) => {
  const { t } = useTranslation()
  const breadcrumbsList = useMemo(() => {
    if (!location) return []

    return Object.entries(location)
      .filter(([_, value]) => !!value)
      .map(([key, value]) => ({
        label: value.name,
        path: key,
      }))
  }, [location])

  return (
    <Flex gap={20} align="center">
      {breadcrumbsList.length > 0 && (
        <Flex gap={24} align="center">
          <Flex className={styles.icon} align="center" justify="center">
            <WppIconOrganization />
          </Flex>
          <Flex direction="column">
            <WppTypography type="xs-midi" className={styles.greyColor800}>
              {t('project.files.locations')}
            </WppTypography>

            <Flex direction="column" gap={4}>
              <WppBreadcrumb
                items={breadcrumbsList}
                className={clsx(styles.breadcrumb, { [styles.single]: breadcrumbsList.length === 1 })}
                maxLabelLength={Number.POSITIVE_INFINITY}
                data-testid="workflow-location"
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
